<template>
  <div>
    <el-card :body-style="{ padding: '10px'}">
      <div slot="header">
        <!-- <div class="title">运费险实时监控</div> -->
        <div class="hello">
          <div class="right">
            <span>投保总单量：{{danliang}}</span>
            <span>投保总份数：{{shuliang}}</span>
            <span :class="red">投保总金额：{{jine}}</span>
            <span>理赔总单量：{{lipeiCount}}</span>
            <span :class="red">理赔总金额：{{lipeiPrice}}</span>
            <span>质量问题总单量：{{zhiliangCount}}</span>
            <span>质量问题总金额：{{zhiliangPrice}}</span>
          </div>
        </div>
        <div class="shaixuan">
          <div>
            <el-date-picker :picker-options="pickerOptionsStart" v-if="bennian" @change="filterData" size="medium" v-model="times1" type="daterange"
              range-separator="至" start-placeholder="开始日期" :unlink-panels="true" value-format="yyyy-MM-dd" end-placeholder="结束日期">
            </el-date-picker>
            <el-date-picker :picker-options="pickerOptionsAll" v-else @change="filterData" size="medium" v-model="times" type="daterange"
              range-separator="至" start-placeholder="开始日期" :unlink-panels="true" value-format="yyyy-MM-dd" end-placeholder="结束日期">
            </el-date-picker>
            <el-button type="primary" size="medium" style="margin-left: 10px" @click="filterData">搜索</el-button>
            <el-checkbox style="margin-left: 20px" border size="medium" v-model="bennian">查看本年</el-checkbox>
          </div>
          <div>
            <el-button type="primary" size="medium" @click="compute">计算选中</el-button>
            <el-button type="success" size="medium" @click="exportExcel">导出选中</el-button>
            <!-- <el-button type="danger" size="medium" @click.once="exit">退出登录</el-button> -->
          </div>
          
        </div>
      </div>
      <el-table :data="tableData" size="medium" stripe v-loading="loading" @selection-change="handleSelectionChange" :row-class-name="checkStyle" height="680" element-loading-text="拼命加载中" @sort-change="sortChange">
        <el-table-column type="selection" width="55" align="center"></el-table-column>
        <el-table-column prop="insured_number"  align="center" label="投保单号" sortable="custom" min-width="150"></el-table-column>
        <el-table-column prop="insured_count"  align="center" label="投保订单数" sortable="custom"></el-table-column>
        <el-table-column prop="insured_yfx"  align="center" label="投保运费险份数" sortable="custom"></el-table-column>
        <el-table-column prop="insured_price"  align="center" label="投保金额" sortable="custom">
          <template v-slot="{ row }">
            <div :class="{ [$style.red]: row.isRed }">{{row.insured_price}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="insured_rate"  align="center" label="投保费率"></el-table-column>
        <el-table-column prop="claim_number"  align="center" label="理赔数量" sortable="custom"></el-table-column>
        <el-table-column prop="claim_price"  align="center" label="理赔金额" sortable="custom">
          <template #{ row }>
            <div :class="row.isRed ? $style.red : ''">{{row.claim_price}}</div>
          </template>
        </el-table-column>
        <el-table-column prop="quality_number"  align="center" label="质量问题理赔数量" min-width="100" sortable="custom"></el-table-column>
        <el-table-column prop="quality_price"  align="center" label="质量问题理赔金额" min-width="100" sortable="custom"></el-table-column>
        <el-table-column prop="insured_date"  align="center" label="投保日期" sortable="custom"></el-table-column>
      </el-table>
      <div class="page">
        <BasePagination :total="total" :now.sync="currentPage" :pageSize="pageSize" @changeSize="changeSize"></BasePagination>
      </div>
      
      <el-dialog title="选中项监控数据" :visible.sync="dialogVisible" width="500px" @close="dialogVisible = false">
        <div class="dialog">
          <span>已选中投保单：{{multipleSelection.length}}</span>
          <span>投保总单量：{{item.insured_count}}</span>
          <span>投保总份数：{{item.insured_yfx}}</span>
          <span :class="red">投保总金额：{{item.insured_price}}</span>
          <span>理赔总单量：{{item.claim_number}}</span>
          <span :class="red">理赔总金额：{{item.claim_price}}</span>
          <span>质量问题总单量：{{item.quality_number}}</span>
          <span>质量问题总金额：{{item.quality_price}}</span>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
    
  </div>
</template>

<script>
import { export_json_to_excel } from "@/plugins/Export2Excel";
import { getFirthDay, getDateNow } from "@/utils/utils";

export default {
  props: {
    msg: String
  },
  data () {
    return {
      bennian: true,
      currentPage: 1,
      pageSize: 50,
      total: 0,
      tableData: [],
      loading: false,
      danliang: 0,
      shuliang: 0,
      jine: 0,
      lipeiPrice: 0,
      lipeiCount: 0,
      zhiliangCount: 0,
      zhiliangPrice: 0,

      multipleSelection: [],
      dialogVisible: false,
      times1: [getFirthDay(), getDateNow()],
      times: [],
      end_time: getDateNow(),

      item: {},
      sortItem: {
        order_type: '',
        order_field: ''
      },   //排序
      pickerOptionsStart: {
        disabledDate: (time) => {
          return time.getTime() < new Date('2022-06-04')
        }
      },
      pickerOptionsAll: {
        disabledDate: () => {
          return false
        }
      }
    }
  },
  computed: {
    red() {
      let flag = this.jine - this.lipeiPrice < 0
      return {
        [this.$style.red]: flag
      }
    },
    selectYear() {
      if(this.bennian) {
        return {start_time: this.times1?.[0], end_time: this.times1?.[1]}
      } else {
        return {start_time: this.times?.[0], end_time: this.times?.[1]}
      }
    },
  },
  watch: {
    currentPage() {
      this.getData()
    },
    bennian() {
      this.getData()
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    sortChange(column) {
      console.log(column);
      switch (column.order) {
        case 'descending':
          this.sortItem.order_type = 'desc'
          break;
      case 'ascending':
          this.sortItem.order_type = 'asc'
          break;
      }
      this.sortItem.order_field = column.prop
      this.filterData()
    },
    changeSize(val) {
      console.log(val);
      this.pageSize = val
      this.filterData()
    },
    exit() {
      // this.$confirm('确定要退出登录吗?', '提示').then(() => {
      //   this.$emit('changeLogin', true)
      //   localStorage.setItem('login', false)
      // })
    },
    checkStyle({row}) {
      if(row.isRed) {
        return this.$style.red
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      // console.log(this.multipleSelection);
    },

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 导出选中
    exportExcel() {        
      if(!this.multipleSelection.length)
        return this.$message.warning('请至少选中一条数据')  
      const excelHeader = ["投保单号", "投保订单数", "投保运费险份数", "投保金额", "投保费率", "理赔数量", "理赔金额", "质量问题理赔数量", "质量问题理赔金额", "投保日期"]
      const filterVal = ["insured_number", "insured_count", "insured_yfx", "insured_price", "insured_rate", "claim_number", "claim_price", "quality_number", "quality_price", "insured_date"]
      const data = this.formatJson(filterVal, this.multipleSelection);
      const excelName='运费险实时监控数据'
      export_json_to_excel(excelHeader, data, excelName)

    },
    // 计算选中
    compute() {
      if(!this.multipleSelection.length)
        return this.$message.warning('请至少选中一条数据')
      this.item = {
        insured_count: 0,
        insured_yfx: 0,
        insured_price: 0,
        claim_number: 0,
        claim_price: 0,
        quality_number: 0,
        quality_price: 0,
      }
      this.multipleSelection.map(o => {
        this.item.insured_count += Number(o.insured_count),
        this.item.insured_yfx += Number(o.insured_yfx),
        this.item.insured_price += Number(o.insured_price),
        this.item.claim_number += Number(o.claim_number),
        this.item.claim_price += Number(o.claim_price),
        this.item.quality_number += Number(o.quality_number),
        this.item.quality_price += Number(o.quality_price)
      })
      this.item.insured_price = this.item.insured_price.toFixed(2)
      this.item.claim_price = this.item.claim_price.toFixed(2)
      this.item.quality_price = this.item.quality_price.toFixed(2)
      this.dialogVisible = true
    },
    filterData() {
      this.currentPage = 1
      this.getData()
    },
    // changeSize(val) {
    //   this.currentPage = val
    // },
    getData() {
      console.log('this.sortItem', this.sortItem);
      this.loading = true
      this.$http.get('/insured_order_data', {
        params: {
          page_number: this.pageSize,
          page: this.currentPage,
          start_time: this.selectYear.start_time,
          end_time: this.selectYear.end_time,
          ...this.sortItem
        }
      }).then(res => {
        this.loading = false
        this.tableData = res.data.data.map(o => {
          if(o.claim_price > o.insured_price)o.isRed = true
          return o
        })
        this.total = res.data.total,
        this.danliang = res.data.total_data.insured_count,
        this.shuliang = res.data.total_data.insured_yfx,
        this.jine = res.data.total_data.total_price,
        this.lipeiCount = res.data.total_data.claim_number,
        this.lipeiPrice = res.data.total_data.claim_price,
        this.zhiliangCount = res.data.total_data.quality_number,
        this.zhiliangPrice = res.data.total_data.quality_price
      }).catch(err => {
        console.log(err);
        this.loading = false
      })
    }
  }
}
</script>

<style module>
.red {
  color: red !important;
  font-weight: bold;
}
</style>
<style scoped>
.hello {
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.title {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
}
.right {
  /* position: absolute;
  right: 0px;
  top: 0px; */
  text-align: right;
  font-size: 16px;
}
.hello span {
  margin-right: 30px;
}
.page {
  padding-top: 10px;
  text-align: center;
}
.dialog {
  width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  font-weight: bold;
  line-height: 36px;
}
.shaixuan {
  /* margin: 0 20px; */
  display: flex;
  justify-content: space-between;
}
</style>
